import Group from 'zp-ui/src/group';

const swInstall = (swState, handlers) => {
  if (!('serviceWorker' in navigator)) {
    swState.setState({ state: 'no-support' });
    return;
  }

  navigator.serviceWorker.register('./sw.js').then((reg) => {
    let serviceWorker;

    if (reg.installing) {
      serviceWorker = reg.installing;
      swState.setState({ state: 'waiting' });
    } else if (reg.waiting) {
      serviceWorker = reg.waiting;
      swState.setState({ state: 'waiting' });
    } else if (reg.active) {
      serviceWorker = reg.active;
      // activating, activated状态
      swState.setState({ state: 'active' });
    }

    if (serviceWorker) {
      serviceWorker.addEventListener('statechange', handlers.stateChange);
    }
  }).catch((err) => {
    swState.setState({ state: 'error' });
    handlers.error(err.message);
  });
};

const createHandler = swState => ({
  /**
   * service worker statechange
   */
  stateChange({ target }) {
    const { state } = target;

    if (state === 'activated') {
      swState.setState({ state: 'active' });
    } else {
      swState.setState({ state: 'waiting' });
    }
  },
  /**
   * service worker error
   */
  error(message) {
    console.log(message); // eslint-disable-line no-console
  },
});

/**
 * service worker状态观察者
 */
const swStateObserver = () => {
  const target = document.querySelector('.sw__status');

  return {
    update({ state }) {
      if (state === 'active') {
        target.innerHTML = 'active';
      } else if (state === 'no-support') {
        target.innerHTML = 'no support';
      } else if (state === 'error') {
        target.innerHTML = 'error';
      } else {
        target.innerHTML = 'waiting';
      }
    },
  };
};

document.addEventListener('DOMContentLoaded', () => {
  const swState = new Group('sw');
  swState.attach(swStateObserver());

  const handlers = createHandler(swState);

  swInstall(swState, handlers);
});
